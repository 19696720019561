<template>
  <div class="outer-page">
    <div class="top-pane">
      <div class="top-left-pane">
        <span style="line-height: 30px">车牌号：</span>
        <Input
          clearable
          placeholder="请输入车牌号查询"
          style="width: 200px; margin-right: 10px"
          v-model="search_data.plate"
        ></Input>
        <span style="line-height: 30px">公司：</span>
        <Input
          clearable
          placeholder="请输入公司名称查询"
          style="width: 200px; margin-right: 10px"
          v-model="search_data.companyName"
        ></Input>

        <Button type="primary" style="margin-right: 10px" @click="oSearch">
          查询</Button
        >
        <Button @click="reset">重置</Button>
      </div>
      <div class="top-right-pane">
        <ul>
          <!-- acvtive -->
          <li
            v-for="(item, index) in statusList"
            :class="currentIndex == index ? '' : ''"
            :key="index"
            @click="changeStatus(index)"
          >
            {{ item.status }}：{{ statusList[index].val }}辆
          </li>
          <!-- <li>在途车辆：24量</li>
          <li>停业车辆：24量</li> -->
        </ul>
      </div>
    </div>
    <div class="center-pane">
      <div class="center-top" ref="centerWidth">
        <div
          ref="itemWidth"
          class="center-pane-item"
          v-for="(item, index) in data"
          :key="index"
          :style="{ marginRight: (index + 1) % num == 0 ? '0' : '18px' }"
        >
          <div class="item-img">
            <img
              :src="
                item.imgUrl && item.imgUrl != 'img/car.png'
                  ? item.servicePath + item.imgUrl
                  : '/imgs/carLogo.jpg'
              "
              alt=""
            />
          </div>
          <div class="item-info">
            <span>{{ item.plate }}</span>
            <div style="display: flex">
              <span style="margin-right: 6px">{{ item.carWorkStatus }}</span>
              <span
                class="iconfont icon-round round"
                :style="{
                  color:
                    item.carWorkStatus == '空闲中'
                      ? '#06baa1'
                      : item.carWorkStatus == '在途中'
                      ? '#F1B14F'
                      : '#99999A',
                }"
              ></span>
            </div>
          </div>
          <div style="font-size: 9px; margin-top: 10px">
            {{ item.companyName }}
          </div>
        </div>
      </div>
      <div class="center-footer">
        <Page
          :current="page.pageNo"
          :page-size="page.pageSize"
          :total="page.total"
          :show-elevator="true"
          :show-total="true"
          class="xpage"
          @on-change="pageChange"
        ></Page>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      widths: "",
      num: "",
      page: {
        total: 0,
        pageSize: 10,
        pageNo: 1,
      },
      data: [],
      search_data: {
        plate: "",
        companyName: "",
      },
      statusList: [
        { status: "空闲车辆", val: 0 },
        { status: "在途车辆", val: 0 },
        { status: "停业车辆", val: 0 },
      ],
      currentIndex: 0,
      // statusNum: {
      //   //空闲中
      //   free: 0,
      //   //在途中
      //   work: 0,
      //   //停业中
      //   closed: 0,
      // },
    };
  },
  methods: {
    getList() {
      return this.$post(this.$api.CAR_MANAGE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      }).then((res) => {
        this.data = res.list;
        this.statusList = [
          { status: "空闲车辆", val: 0 },
          { status: "在途车辆", val: 0 },
          { status: "停业车辆", val: 0 },
        ];
        res.list.forEach((item) => {
          if (item.carWorkStatus == "空闲中") {
            this.statusList[0].val += 1;
          } else if (item.carWorkStatus == "在途中") {
            this.statusList[1].val += 1;
          } else {
            this.statusList[2].val += 1;
          }
        });
        this.page.total = +Number(res.total);
      });
    },
    oSearch() {
      this.getList();
    },
    reset() {
      this.search_data.plate = "";
      this.search_data.companyName = "";
    },
    changeStatus(index) {
      this.currentIndex = index;
    },
    resize() {
      // 获取div宽度
      this.widths = this.$refs.centerWidth.clientWidth;
      let i = this.$refs.itemWidth[0].clientWidth;
      let temp = (Math.floor(this.widths / i) - 1) * 18;
      this.num = Math.floor((this.widths - temp) / i);
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
  },
  mounted() {
    this.getList().then(() => {
      this.resize();
    });
    // 监听屏幕变化
    window.addEventListener("resize", this.resize);
    this.$nextTick(() => {
      this.widths = this.$refs.centerWidth.clientWidth;
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  // width: 100%;
  width: calc(~"100% - 4px");
  height: 100%;
  margin-left: 2px;
  margin-top: 2px;
  .top-pane {
    height: 80px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 0 4px 0 #ccc;
    display: flex;
    justify-content: space-between;
    .top-left-pane {
      padding: 20px;
      display: flex;
      justify-content: space-between;
    }
    .top-right-pane {
      ul {
        display: flex;
        height: 100%;
        li {
          display: inline-block;
          height: 100%;
          line-height: 80px;
          padding: 0 20px;
          overflow: hidden;
          cursor: pointer;
        }
        .active {
          background-color: #06baa1;
          color: #fff;
        }
      }
    }
  }
  .center-pane {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    .center-top {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      padding-top: 2px;
      padding-left: 2px;
      .center-pane-item {
        width: 314px;
        height: 378px;
        background-color: #fff;
        box-shadow: 0 0 4px 0 #ccc;
        border-radius: 4px;
        margin-bottom: 18px;
        padding: 20px;
        .item-img {
          // border: 1px solid red;
          border-radius: 4px;
          overflow: hidden;
          width: 100%;
          height: 270px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item-info {
          margin-top: 15px;
          display: flex;
          justify-content: space-between;
          font-weight: bold;
          font-size: 15px;
          .icon-round {
            font-size: 12px;
            font-weight: bold;
          }
          .round {
            color: #06baa1;
            display: inline-block;
            margin-top: 2px;
          }
        }
      }
    }
    .center-footer {
      text-align: right;
    }
  }
}
</style>