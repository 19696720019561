var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outer-page"},[_c('div',{staticClass:"top-pane"},[_c('div',{staticClass:"top-left-pane"},[_c('span',{staticStyle:{"line-height":"30px"}},[_vm._v("车牌号：")]),_c('Input',{staticStyle:{"width":"200px","margin-right":"10px"},attrs:{"clearable":"","placeholder":"请输入车牌号查询"},model:{value:(_vm.search_data.plate),callback:function ($$v) {_vm.$set(_vm.search_data, "plate", $$v)},expression:"search_data.plate"}}),_c('span',{staticStyle:{"line-height":"30px"}},[_vm._v("公司：")]),_c('Input',{staticStyle:{"width":"200px","margin-right":"10px"},attrs:{"clearable":"","placeholder":"请输入公司名称查询"},model:{value:(_vm.search_data.companyName),callback:function ($$v) {_vm.$set(_vm.search_data, "companyName", $$v)},expression:"search_data.companyName"}}),_c('Button',{staticStyle:{"margin-right":"10px"},attrs:{"type":"primary"},on:{"click":_vm.oSearch}},[_vm._v(" 查询")]),_c('Button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1),_c('div',{staticClass:"top-right-pane"},[_c('ul',_vm._l((_vm.statusList),function(item,index){return _c('li',{key:index,class:_vm.currentIndex == index ? '' : '',on:{"click":function($event){return _vm.changeStatus(index)}}},[_vm._v(" "+_vm._s(item.status)+"："+_vm._s(_vm.statusList[index].val)+"辆 ")])}),0)])]),_c('div',{staticClass:"center-pane"},[_c('div',{ref:"centerWidth",staticClass:"center-top"},_vm._l((_vm.data),function(item,index){return _c('div',{key:index,ref:"itemWidth",refInFor:true,staticClass:"center-pane-item",style:({ marginRight: (index + 1) % _vm.num == 0 ? '0' : '18px' })},[_c('div',{staticClass:"item-img"},[_c('img',{attrs:{"src":item.imgUrl && item.imgUrl != 'img/car.png'
                ? item.servicePath + item.imgUrl
                : '/imgs/carLogo.jpg',"alt":""}})]),_c('div',{staticClass:"item-info"},[_c('span',[_vm._v(_vm._s(item.plate))]),_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticStyle:{"margin-right":"6px"}},[_vm._v(_vm._s(item.carWorkStatus))]),_c('span',{staticClass:"iconfont icon-round round",style:({
                color:
                  item.carWorkStatus == '空闲中'
                    ? '#06baa1'
                    : item.carWorkStatus == '在途中'
                    ? '#F1B14F'
                    : '#99999A',
              })})])]),_c('div',{staticStyle:{"font-size":"9px","margin-top":"10px"}},[_vm._v(" "+_vm._s(item.companyName)+" ")])])}),0),_c('div',{staticClass:"center-footer"},[_c('Page',{staticClass:"xpage",attrs:{"current":_vm.page.pageNo,"page-size":_vm.page.pageSize,"total":_vm.page.total,"show-elevator":true,"show-total":true},on:{"on-change":_vm.pageChange}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }